
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col

} from 'reactstrap';


class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            citySearch: undefined,
            streetSearch: undefined,
            results: [],
            cities: []
        };
    }

    componentDidMount() {

    }

    searchCity = () => {
        fetch('https://rossen.se:61002/locations/city/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search: this.state.citySearch })
        }).then((res) => res.json()).then((result) => {
            this.setState({
                cities: result,
                cityDropdownOpen: true
            })
        })
    }
    searchStreet = () => {
        fetch('https://rossen.se:61002/locations/street/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search: this.state.streetSearch, placeId: this.props.value.placeId })
        }).then((res) => res.json()).then((result) => {
            this.setState({
                streets: result,
                streetDropdownOpen: true
            })
        })
    }

    render() {
        return (
            <Row>
                <Col lg="6">
                    <div className="input-wrap search-select address-select">
                        {this.props.label ? <label>Grad</label> : null}

                        <Dropdown className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.cityDropdownOpen} toggle={() => { this.setState({ cityDropdownOpen: !this.state.cityDropdownOpen }) }}>
                            <DropdownToggle nav caret>
                                <input type="text" onChange={(e) => this.setState({ citySearch: e.target.value }, this.searchCity)} value={this.state.citySearch !== undefined ? this.state.citySearch : this.props.value ? this.props.value.place + ' ' + this.props.value.zip : this.props.placeholder}></input>
                            </DropdownToggle>
                            {this.state.cities && this.state.cities.length ?
                                <DropdownMenu className="dropdown-animation">
                                    {
                                        this.state.cities.map((children, idx) => {
                                            return (
                                                <DropdownItem onClick={() => {
                                                    this.setState({
                                                        citySearch: children.placeName + ' ' + children.zip,
                                                        streetSearch: undefined
                                                    })
                                                    this.props.onChange({
                                                        placeId: children.placeId,
                                                        cityId: children.cityId,
                                                        zip: children.zip,
                                                        place: children.placeName,
                                                        street: '',
                                                        streetId: null
                                                    })
                                                }}>{children.placeName}  {children.zip}</DropdownItem>
                                            )
                                        })
                                    }
                                </DropdownMenu>
                                :
                                null
                            }
                        </Dropdown>
                    </div>

                </Col>

                <Col lg="6">
                    {this.props.value && this.props.value.placeId ?
                        <div className="input-wrap search-select address-select">
                            {this.props.label ? <label>Ulica</label> : null}

                            <Dropdown className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.streetDropdownOpen} toggle={() => { this.setState({ streetDropdownOpen: !this.state.streetDropdownOpen }) }}>
                                <DropdownToggle nav caret>
                                    <input type="text" onChange={(e) => this.setState({ streetSearch: e.target.value }, this.searchStreet)} value={this.state.streetSearch !== undefined ? this.state.streetSearch : this.props.value ? this.props.value.street  : this.props.placeholder}></input>
                                </DropdownToggle>
                                {this.state.streets && this.state.streets.length ?
                                    <DropdownMenu className="dropdown-animation">
                                        {
                                            this.state.streets.map((children, idx) => {
                                                return (
                                                    <DropdownItem onClick={() => {
                                                        this.setState({
                                                            streetSearch: children.name
                                                        })
                                                        this.props.onChange({
                                                            ...this.props.value,
                                                            street: children.name,
                                                            streetId: children.id
                                                        })
                                                    }}>{children.name}</DropdownItem>
                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                    :
                                    null
                                }
                            </Dropdown>
                        </div>
                        :
                        null
                    }

                </Col>

            </Row>
        );
    }
}

export default Select;