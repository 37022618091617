
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Col,
    Row,
    Container
} from 'reactstrap';
import Select from './selectWithSearch';
import NormalSelect from './select';
import Check from './check2';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let products

        let value = this.props.value ? this.props.value : [];

        
        let discountAliasArray = ["009"];
        if (value && value.length) {
            for (let i = 0; i < value.length; i++) {
                if (value[i] && value[i].Alias && discountAliasArray.indexOf(value[i].Alias) !== -1 && value[i].package && value[i].package.discount && value[i].package.quantityDiscount && value[i].package.quantityDiscount.length && value[i].cartQuantity) {
                    let quantityDiscount = value[i].package.quantityDiscount;
                    let usePriceWithoutDiscount = false;
                    let cartQuantity = Number(value[i].cartQuantity);
                    if (quantityDiscount && quantityDiscount.length) {

                        for (let i = 0; i < quantityDiscount.length; i++) {
                            if (quantityDiscount[i].quantity) {
                                if (cartQuantity >= quantityDiscount[i].quantity) {
                                    usePriceWithoutDiscount = true;
                                }
                            }
                        }
                    }
                    if (usePriceWithoutDiscount) {
                        if (value[i].package.originalPrice) {
                            value[i].package.price = value[i].package.originalPrice;
                        }
                        let checkPrice = value[i].package && value[i].package.discount ? value[i].package.price && (value[i].package.price * (1 + (value[i].package.upDiscount || value[i].package.discount) / 100)) : value[i].package && value[i].package.price;

                        if (checkPrice) {
                            if (value[i].__discount)
                                checkPrice = Math.floor(checkPrice * (1 - (value[i].__discount / 100)));
                            // checkPrice = checkPrice.toFixed(2)
                        }
                        if (checkPrice) {
                            value[i].package.originalPrice = value[i].package.price;
                            value[i].package.price = checkPrice;
                            value[i].price = checkPrice
                            value[i].__price = checkPrice
                        }
                    }else{
                        if (value[i].package.originalPrice) {
                            value[i].package.price = value[i].package.originalPrice;
                        }
                    }

                }
            }
        }

        if (new Date().getTime() >= new Date(2023, 10, 20, 0, 0, 0, 0).getTime() && new Date().getTime() <= new Date(2023, 11, 1, 23, 59, 59, 999).getTime()) {
            let aliasArray = ["010", "009", "023", "046", "004", "045"]
            if (value && value.length) {
                for (let i = 0; i < value.length; i++) {
                    if (value[i] && value[i].Alias && aliasArray.indexOf(value[i].Alias) !== -1 && value[i].package && value[i].package.discount  && value[i].cartQuantity && value[i].package.price !== 0) {
                        let usePriceWithoutDiscount = true;
                    
                        if (usePriceWithoutDiscount) {
                            if (value[i].package.originalPrice) {
                                value[i].package.price = value[i].package.originalPrice;
                            }
                            let checkPrice = value[i].package && value[i].package.discount ? value[i].package.price && (value[i].package.price * (1 + (value[i].package.upDiscount || value[i].package.discount) / 100)) : value[i].package && value[i].package.price;
    
                            if (checkPrice) {
                                if (value[i].__discount) {
                                    checkPrice = checkPrice * (1 - (value[i].__discount / 100));
                                  checkPrice = Number(checkPrice.toFixed(2))
                                }
                            }
                            if (checkPrice) {
                                value[i].package.originalPrice = value[i].package.price;
                                value[i].package.price = checkPrice;
                                value[i].price = checkPrice
                                value[i].__price = checkPrice
                            }
                        }else{
                            if (value[i].package.originalPrice) {
                                value[i].package.price = value[i].package.originalPrice;
                            }
                        }
    
                    }
                }
            }
        }

        return (
            <div className="order-view">
                <table>
                    <tr>
                        <th>Artikal</th>
                        <th>Pakovanje</th>
                        <th>Cijena</th>
                        <th>Kolicina</th>
                        <th>Popust [%]</th>
                        <th>Iznos</th>
                        <th>Gratis</th>
                        <th>Akcija</th>
                    </tr>

                    {
                        [...value, {}].map((row, ridx) => {
                            return (
                                <tr>

                                    <td>
                                        <Select onChange={(id) => {
                                            let changeValue = value;
                                            // if (ridx == value.length - 1) {
                                            //     changeValue.push({});
                                            // }
                                            if (!changeValue[ridx]) {
                                                changeValue[ridx] = {}
                                            }

                                            changeValue[ridx]._id = id;
                                            changeValue[ridx].Name = this.props.products.filter(item => item._id == id)[0].Name;
                                            changeValue[ridx].Alias = this.props.products.filter(item => item._id == id)[0].Alias;

                                            changeValue[ridx].package = null;
                                            changeValue[ridx].cartQuantity = 1;

                                            this.props.onChange(changeValue);
                                            this.forceUpdate()

                                        }} value={row._id}>
                                            {this.props.products.map((item, idx) => {
                                                return <option search={item.Alias + ' - ' + item.Name} value={item._id}>{item.Alias + ' - ' + item.Name}</option>
                                            })}
                                        </Select>
                                    </td>
                                    <td>
                                        {row._id ?
                                            <NormalSelect onChange={(id) => {
                                                let changeValue = value;
                                                changeValue[ridx].package = JSON.parse(id);
                                                changeValue[ridx].package.price = (changeValue[ridx].package.mpc * 1.2).toFixed(2)
                                                changeValue[ridx].price = (changeValue[ridx].package.mpc * 1.2).toFixed(2)
                                                changeValue[ridx].__price = (changeValue[ridx].package.mpc * 1.2).toFixed(2)

                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }} value={JSON.stringify(
                                                Object.values(this.props.products.filter(item => item._id == row._id)[0].package).filter((item) => row.package && item.name == row.package.name)[0])}>
                                                {Object.values(this.props.products.filter(item => item._id == row._id)[0].package).filter((item) => item.name).map((item, idx) => {
                                                    return <option value={JSON.stringify(item)}>{item.name}</option>
                                                })}
                                            </NormalSelect>
                                            :
                                            null
                                        }


                                    </td>
                                    <td>{row.package ? row.package.price : null}</td>
                                    <td>
                                        {row.package ?
                                            <input value={row.cartQuantity} onChange={(e) => {
                                                let changeValue = value;
                                                changeValue[ridx].cartQuantity = parseInt(e.target.value)
                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }}></input>
                                            :
                                            null
                                        }
                                    </td>
                                    <td>
                                        {row.package ?
                                            <input value={row.__discount} onChange={(e) => {
                                                let changeValue = value;
                                                changeValue[ridx].__discount = e.target.value
                                                if (changeValue[ridx].__discount){
                                                changeValue[ridx].package.price = changeValue[ridx].__price * (1 - (changeValue[ridx].__discount / 100));
                                                changeValue[ridx].price = changeValue[ridx].__price * (1 - (changeValue[ridx].__discount / 100));
                                                }else{
                                                    changeValue[ridx].package.price = changeValue[ridx].__price;
                                                    changeValue[ridx].price = changeValue[ridx].__price;
    
                                                }
                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }}></input>
                                            :
                                            null
                                        }
                                    </td>

                                    <td>{row.package ? row.package.price * row.cartQuantity : null}</td>
                                    <td><Check translate={this.props.translate} value={row.price === 0} onChange={(v) => {
                                        
                                        if (v) {
                                            console.log('first if')
                                            let changeValue = value;
                                            changeValue[ridx].cartQuantity = 1;
                                            changeValue[ridx].price = 0;
                                            changeValue[ridx].package.price = 0;
                                            this.props.onChange(changeValue);
                                            this.forceUpdate()
    
                                        }else{
                                            console.log('second if')

                                            let changeValue = value;
                                            changeValue[ridx].cartQuantity = 1;
                                            changeValue[ridx].price = changeValue[ridx].__price;
                                            changeValue[ridx].package.price = changeValue[ridx].__price;

                                            this.props.onChange(changeValue);
                                            this.forceUpdate()
    
                                        }

                                    }}></Check></td>

                                    <td>
                                        {row._id ?
                                            <button type="button" onClick={() => {
                                                let changeValue = value;
                                                value.splice(ridx, 1);
                                                this.props.onChange(changeValue);
                                                this.forceUpdate()

                                            }} className="button" ><i className="mdi mdi-delete"></i> </button>


                                            :
                                            null}
                                    </td>

                                </tr>

                            )
                        })
                    }
                </table>
            </div>
        );
    }
}

export default List;