import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';

import FilePicker from '../components/forms/fields/csv';

import moment from 'moment';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class Import extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: []
        };
    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('adminFetch', (data) => {
            console.log(data);
            this.props.handleLoader(null);
            this.setState({
                products: data.result,
            });
        });


        this.props.socketIOClient.emit('adminFetch', {
            collection: 'products',
            skip: 0,
            query: {},
            sort: {}
        })

        //this.props.socketIOClient.emit('adminStatistic', {  });

    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }

    generateCsvTemplate = () => {
        let csv = `"name","street","city","phone","email",`;
        let productKeys = [];
        for (let i = 0; i < this.state.products.length; i++) {
            productKeys.push(`"${this.state.products[i].Alias}"`);
        }


        csv += productKeys.join(',');
        csv += '\n';

        return 'data:text/csv;charset=utf-8,' + csv;
    }


    render() {

        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }

                <div className="panel ">
                    <div className="panel-title">
                        <h1>{this.props.translate('CSV Import')}</h1>
                        <h6>                        {this.state.products.length ?
                            <a download="template.csv" href={this.generateCsvTemplate()}>Preuzmite CSV template</a>
                            :
                            null
                        }
</h6>

                    </div>




                </div>


                <Container fluid>

                    <Row>

                        <Col lg="6">
                            <FilePicker {...this.props} value={this.state.file} onChange={(val) => this.setState({file: val})} label={'Prevucite .csv fajl kako bi importovali narudzbe.'} />

                        </Col>
                    </Row>
                </Container>

            </div>



        );
    }
}

export default PageWithLayout(Import);